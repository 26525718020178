import { graphql, Link, useStaticQuery } from 'gatsby';
import { Col, Row } from "react-bootstrap";
import { generateSmartAuthorURLS, generateSmartDeliveryURLS } from '../../utils/cloudinary';
import { getDate } from "../../utils/getDate";
import { generateModifiedSlug } from '../../utils/slug';
import React from "react"
const BlogSlider = () => {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    // marginRight: "30px",
                    background: "#19ddc4",
                    borderRadius: "50%",
                    zIndex: 2,
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    padding: "10px"
                }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    // marginLeft: "30px",
                    background: "#19ddc4",
                    borderRadius: "50%",
                    zIndex: 2,
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    padding: "10px"
                }}
                onClick={onClick}
            />
        );
    }
    const BlogDate = [
        "январь",
        "февраль",
        "марш",
        "апреля",
        "май",
        "июнь",
        "июль",
        "август",
        "сентябрь",
        "октябрь",
        "ноябрь",
        "Декабрь",
    ];



    const data = useStaticQuery(graphql`
        query GhostPost {
            allGhostPost(
                filter: {tags: {elemMatch: {name: {eq: "EXAM_BOOSTER"}}}, featured: {eq: true}}
              ){
            edges {
                node {
                ...GhostPostFields
                }
            }
          }
          allGhostPage(filter: { featured: { eq: true } }) {
            edges {
              node {
                ...GhostPageFields
              }
            }
          }
        }
    `)

    const posts = data?.allGhostPost?.edges;
    const pages = data?.allGhostPage?.edges;
    const featuredData = posts.concat(pages);
    // const n = 3;
    // const posts = unSortedposts?.sort(() => Math.random() - 0.5).slice(0, n);
    function containsWhitespace(str) {
        return /\s/.test(str);
    }




    return (
        <div className='py-5'>
            <div className='global'>

                {/* <Slider {...settings}> */}
                <Row>


                    {featuredData && featuredData?.map((post) => {
                        let authorLink = post?.node?.primary_author?.name;
                        const authorLinkSpace = containsWhitespace("  ");
                        authorLink =
                            authorLinkSpace &&
                            authorLink.substring(0, authorLink.indexOf(" "));
                        return (
                            <Col lg={4} md={6} className="my-3" key={post?.node.id}>
                                <div className="cards-wrapper">
                                    <Link style={{ textDecoration: "none" }} to={`/${generateModifiedSlug(post?.node?.slug)}`}>
                                        <div className="card-img-top">
                                            <div className="card_imgs">
                                                <img
                                                    src={generateSmartDeliveryURLS(post?.node?.feature_image)}
                                                    alt="Card image"
                                                    variant="left"
                                                    loading='lazy'
                                                    width="100%"
                                                    height="250px"
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="card-body-content mt-auto">
                                        <Link style={{ textDecoration: "none" }} to={`/${generateModifiedSlug(post?.node?.slug)}`}>
                                            <div className="card_body">
                                                <div className="card_title ">
                                                    {post?.node?.title}
                                                </div>
                                                <p className="card_text">
                                                    {post?.node?.excerpt?.substring(0, 150) + "..."}
                                                </p>
                                            </div>
                                        </Link>
                                        <div className="card_footer">
                                            <Link
                                                href={`/${generateModifiedSlug(post?.node?.primary_author?.slug)}`}
                                            >
                                                <div className="admin_data">
                                                    <img
                                                        width="40px"
                                                        height="40px"
                                                        src={
                                                            post?.node?.primary_author?.profile_image !== null
                                                                ? generateSmartAuthorURLS(post?.node?.primary_author?.profile_image)
                                                                : ""
                                                            // { AuthorImage }
                                                        }
                                                        alt="author"
                                                    />
                                                    <div className="admin-content">
                                                        <small className="author_name">
                                                            {post?.node?.primary_author?.name}
                                                        </small>
                                                        <small className="publish_date">
                                                            {getDate(post?.node?.published_at_pretty)}
                                                            {/* {post?.node?.updated_at_pretty} */}
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        );
                    })}
                </Row>
                {/* </Slider> */}



            </div>

        </div>
    )
}

export default BlogSlider